import { UserInfo, ConversationRequest, Conversation, ChatMessage, CosmosDBHealth, CosmosDBStatus, CustomClientInfo } from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";


export async function submit_captcha(token: string): Promise<Response> {
    const response = await fetch("/validate_recaptcha", {
        method: "POST",
        body: JSON.stringify({
            token: token
        }),
        headers: {
            "Content-Type": "application/json"
        },
    });

    return response;
}

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal): Promise<Response> {
    
    let errRes: Response = {
        ...new Response,
        ok: false,
        status: 500,
    }
    return errRes
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch('/.auth/me');
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {
export const fetchChatHistoryInit = (): Conversation[] | null => {
    // Make initial API call here

    // return null;
    return chatHistorySampleData;
}

export const historyList = async (offset=0): Promise<Conversation[] | null> => {
     
    return null;
}

export const historyRead = async (convId: string): Promise<ChatMessage[]> => {
     
    return [];
}

export const historyGenerate = async (options: ConversationRequest, abortSignal: AbortSignal, convId?: string): Promise<Response> => {
    let body;
    if(convId){
        body = JSON.stringify({
            conversation_id: convId,
            messages: options.messages
        })
    }else{
        body = JSON.stringify({
            messages: options.messages
        })
    }
    const response = await fetch("/history/generate", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: body,
        signal: abortSignal
    }).then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        return new Response;
    })
    return response
}

export const historyUpdate = async (messages: ChatMessage[], convId: string): Promise<Response> => {
    const response = await fetch("/history/update", {
        method: "POST",
        body: JSON.stringify({
            conversation_id: convId,
            messages: messages
        }),
        headers: {
            "Content-Type": "application/json"
        },
    }).then(async (res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue fetching your data.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}

export const historyDelete = async (convId: string) : Promise<Response> => {
    
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes
}

export const historyDeleteAll = async () : Promise<Response> => {
     
    let errRes: Response = {
        ...new Response,
        ok: false,
        status: 500,
    }
    return errRes
}

export const historyClear = async (convId: string) : Promise<Response> => {
     
    let errRes: Response = {
        ...new Response,
        ok: true,
        status: 200,
    }
    return errRes
}

export const historyRename = async (convId: string, title: string) : Promise<Response> => {
    
    let errRes: Response = {
        ...new Response,
        ok: false,
        status: 500,
    }
    return errRes
}

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
    
            return {
                cosmosDB: false,
                status: CosmosDBStatus.NotConfigured
            }
    

}

export const frontendSettings = async (): Promise<Response | null> => {
    const response = await fetch("/frontend_settings", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching your data.");
        return null
    })

    return response
}
export const historyMessageFeedback = async (messageId: string, feedback: string): Promise<Response> => {
    const response = await fetch("/history/message_feedback", {
        method: "POST",
        body: JSON.stringify({
            message_id: messageId,
            message_feedback: feedback
        }),
        headers: {
            "Content-Type": "application/json"
        },
    })
    .then((res) => {
        return res
    })
    .catch((err) => {
        console.error("There was an issue logging feedback.");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response;
}

export const getClientInfo = async (): Promise<CustomClientInfo> => {
    const response = await fetch("/misc/get_client_info", {
        method: "GET",
    }).then((res) => {
        return res.json()
    }).catch((err) => {
        console.error("There was an issue fetching client info...");
        let errRes: Response = {
            ...new Response,
            ok: false,
            status: 500,
        }
        return errRes;
    })
    return response
}