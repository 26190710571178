import { useRef, useState, useEffect, useContext, useLayoutEffect } from "react";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { AppStateContext } from "../src/state/AppProvider";
import { CustomClientInfo } from "./api/models";


let appInsights;
const reactPlugin = new ReactPlugin();
const AppInsightsLogger = () => {
    const appStateContext = useContext(AppStateContext);
    appInsights = new ApplicationInsights({ config: {
        connectionString: (window as any).REACT_APP_INSTRUMENTATION_KEY,//appStateContext?.state.frontendSettings?.appInsights_connectionString,       
        extensions: [reactPlugin],
        disableCorrelationHeaders: false,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true
    
        /* ...Other Configuration Options... */
    } })   
    if ((window as any).REACT_APP_INSTRUMENTATION_KEY) {       
        appInsights.loadAppInsights();    
    }  
}

const trackPageView = (pageName: string, clientInfo?: CustomClientInfo) => {
    if ( !appInsights || !appInsights.config || !appInsights.config.connectionString) {
        AppInsightsLogger();
    }
    else{
        //appInsights.trackPageView({ name: pageName });
        appInsights.trackPageView({
            name: pageName,
            properties: {
              ...clientInfo,  // append custom properties to the page view
            },
          });
        appInsights.flush();
    }
        
}

const trackException = (error: Error) => {   
    if ( !appInsights || !appInsights.config || !appInsights.config.connectionString) {
        AppInsightsLogger();
    }
    else{
        appInsights.trackException({ exception:error });
        appInsights.flush(); 
    }
    
}


const trackTrace = (message: object) => {   
    if ( !appInsights || !appInsights.config || !appInsights.config.connectionString) {
        AppInsightsLogger();
    }
    else{
        appInsights.trackTrace({ message: message });
        appInsights.flush(); 
    }  
}

export{AppInsightsLogger,trackPageView,trackException,trackTrace};